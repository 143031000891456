import { getCookie, setCookie } from 'cookies-next';
import { twMerge } from 'tailwind-merge';
import { clsx, ClassValue } from 'clsx';
export function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const cn = classNames; // Alias for classNames

// Preset color themes
export const presetColorThemes = [
  { name: 'Purple', mainButton: 'bg-gradient-4 text-white', pageShadowGradient: 'from-purple-100', bgColor: 'bg-purple-500', ringColor: 'ring-purple-500 focus:border-purple-500 focus:ring-purple-500', hoverColor: 'hover:bg-purple-600' },
   //FIXME: Indigo theme should be created
   { name: 'Indigo', mainButton: 'bg-gradient-4 text-white', pageShadowGradient: 'from-indigo-100', bgColor: 'bg-indigo-500', ringColor: 'ring-indigo-500 focus:border-indigo-500 focus:ring-indigo-500', hoverColor: 'hover:bg-indigo-600' },
   //FIXME: Green theme should be created
  { name: 'Green', mainButton: 'bg-gradient-4 text-white', pageShadowGradient: 'from-green-100', bgColor: 'bg-green-500', ringColor: 'ring-green-500 focus:border-green-500 focus:ring-green-500', hoverColor: 'hover:bg-green-600' },
  { name: 'Amber', mainButton: 'bg-gradient-3 text-white', pageShadowGradient: 'from-amber-100', bgColor: 'bg-amber-600', ringColor: 'ring-amber-600 focus:border-amber-600 focus:ring-amber-600', hoverColor: 'hover:bg-amber-700' },
  { name: 'Pink', mainButton: 'bg-gradient-2 text-white', pageShadowGradient: 'from-pink-100', bgColor: 'bg-pink-500', ringColor: 'ring-pink-500 focus:border-pink-500 focus:ring-pink-500', hoverColor: 'hover:bg-pink-600' },
];

// Utility function to return the correct class for provided platform ('instagram' or 'tiktok')
export function getPlatformClass(platform: string, instagramClasses: string, tiktokClasses: string, defaultClasses?: string): string {
  switch (platform?.toLowerCase()) {
    case 'instagram':
      return instagramClasses;
    case 'tiktok':
      return tiktokClasses;
    default:
      return defaultClasses || instagramClasses;
  }
}

export function getPlatformForAccount(accountId: string | null | undefined): string {
  if (!accountId) return 'instagram'; // Default to 'instagram' if no accountId
  const platformCookie = getCookie(`account_platform_${accountId}`);
  return platformCookie ? String(platformCookie) : 'instagram';
}

export function setPlatformForAccount(accountId: string, platform: string): void {
  setCookie(`account_platform_${accountId}`, platform, { maxAge: 60 * 60 * 24 * 365, path: '/' }); // 1 year expiration
}