"use client"
import React, { useState, useEffect } from 'react';

export default function BannerCountdown() {
  const [shouldShow, setShouldShow] = useState(false);
  const [time, setTime] = useState({ hours: '00', minutes: '00', seconds: '00' });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const nextHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1, 0, 0);
      const diff = nextHour.getTime() - now.getTime();
      let hours = Math.floor(diff / 1000 / 60 / 60);
      let minutes = Math.floor(diff / 1000 / 60) % 60;
      const seconds = Math.floor(diff / 1000) % 60;

      // If less than 15 minutes left, show time until next hour
      if (minutes < 15 && hours === 0) {
        hours = 1;
        minutes = 60 - now.getMinutes();
      }

      setTime({
        hours: hours.toString().padStart(1, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      });
      if (!shouldShow) setShouldShow(true);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`flex items-center justify-center space-x-2 ${!shouldShow && 'opacity-70'}`}>
      <div className={`flex flex-col items-center justify-center drop-shadow px-2.5 py-1 bg-white rounded-lg ${!shouldShow && 'animate-pulse'}`} style={{ maxWidth: '38px' }}>
        <span className={`text-sm font-bold leading-4 text-transparent hero-text-gradient bg-clip-text bg-[length:170%] saturate-[1.2]`}>
          {shouldShow ? time.hours : '\u200B'}
        </span>
        <span className="text-xs font-semibold leading-3 text-gray-700" style={{
          fontSize: "11px"
        }}>HR</span>
      </div>
      <div className={`flex flex-col items-center justify-center drop-shadow px-2.5 py-1 bg-white rounded-lg ${!shouldShow && 'animate-pulse'}`} style={{ maxWidth: '38px' }}>
        <span className={`text-sm font-bold leading-4 text-transparent hero-text-gradient bg-clip-text bg-[length:170%] saturate-[1.2]`}>
          {shouldShow ? time.minutes : '\u200B'}
        </span>
        <span className="text-xs font-semibold leading-3 text-gray-700" style={{
          fontSize: "11px"
        }}>MIN</span>
      </div>
      <div className={`flex flex-col items-center justify-center drop-shadow px-2.5 py-1 bg-white rounded-lg ${!shouldShow && 'animate-pulse'}`} style={{ maxWidth: '38px' }}>
        <span className={`text-sm font-bold leading-4 text-transparent hero-text-gradient bg-clip-text bg-[length:170%] saturate-[1.2]`}>
          {shouldShow ? time.seconds : '\u200B'}
        </span>
        <span className="text-xs font-semibold leading-3 text-gray-700" style={{
          fontSize: "11px"
        }}>SEC</span>
      </div>
    </div>
  );
}