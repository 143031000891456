import(/* webpackMode: "eager", webpackExports: ["I18nProviderClient"] */ "/vercel/path0/apps/upgrow/locales/client.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/upgrow/public/css/landing.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LanguageDropdown.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/upgrow/src/components/landing/navigation/BannerCountdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/navigation/BannerSpotsLeft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/navigation/LandingNavbarCTAButton.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/upgrow/src/components/landing/navigation/LandingNavbarPopover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/navigation/NavLinks.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-ui/components/Anchor.tsx");
