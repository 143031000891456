"use client"
import { useEffect, useState } from 'react';
import { hasCookie } from 'cookies-next';
import Anchor from 'shared-ui/components/Anchor';
import { classNames } from 'src/lib/utils';

export default function LandingNavbarCTAButton({ className, isWhiteVersion = false }: { className?: string, isWhiteVersion?: boolean }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const cookies = Object.fromEntries(document.cookie.split('; ').map(cookie => cookie.split('=')));
        const supabaseAuthCookie = Object.keys(cookies).find(name => /^sb-.+-auth-token$/.test(name));
        setIsLoggedIn(hasCookie('supabase-auth-token') || hasCookie(supabaseAuthCookie));
    }, []);

    return (
        isLoggedIn ? (
            <Anchor href="/app" className={classNames("group hidden text-white rounded-lg px-4 py-1.5 text-base font-semibold leading-7 shadow-sm transition focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white lg:inline-flex",
                isWhiteVersion ? "bg-white/10" : "bg-gray-800 hover:bg-gray-900 active:bg-gray-800 active:text-white/80"
            )}>
                Go to Dashboard
                <svg className="my-auto ml-2 -mr-1 stroke-2 stroke-white" fill="none" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                    <path className="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path><path className="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
                </svg>
            </Anchor>
        ) : (
            <>
                    <Anchor href="/login" className={classNames("hidden rounded-lg border-gray-300 px-3.5 py-1.5 text-base font-medium leading-7 duration-200 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white lg:block",
                        isWhiteVersion ? "text-white hover:bg-white/10 active:bg-white/20" : "text-gray-700 hover:bg-gray-50 active:bg-gray-100 active:text-gray-700/80"
                    )}>
                    Log in
                </Anchor>
                    <Anchor href="/setup" className={classNames("hidden rounded-lg px-4 py-1.5 text-base font-semibold leading-7 transition-all duration-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white lg:block",
                        isWhiteVersion ? "bg-white text-gray-800 hover:bg-opacity-80" : "bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80"
                    )}>
                    Start for free
                </Anchor>
            </>
        )
    )
}