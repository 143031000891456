"use client"
import { Fragment } from 'react';
import clsx from 'clsx';
import { Popover, Transition } from '@headlessui/react';
import Anchor from 'shared-ui/components/Anchor';

// Icons
import { RiChatSmile3Line } from 'react-icons/ri';
import { ChevronDownIcon, PlayCircleIcon, } from '@heroicons/react/20/solid';
import { TbTools, TbTargetArrow, TbSparkles } from 'react-icons/tb';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { TiChartLineOutline } from 'react-icons/ti';
import { RiLightbulbFlashLine, RiMoneyDollarCircleLine } from 'react-icons/ri';
import { classNames } from 'src/lib/utils';

const featuresDropdown = [
  {
    name: 'Instagram Growth',
    description: 'Grow 10x faster with patented AI growth engine',
    href: '/instagram-growth-service',
    icon: TiChartLineOutline,
    iconClass: "bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500",
    badge: 'v2.0',
  },
  {
    name: 'Instagram Analytics',
    description: "See what's working, and what isn't, in real-time",
    href: '/sign-up',
    // href: '/instagram-analytics',
    icon: TbTargetArrow,
    iconClass: "bg-gradient-to-tl from-orange-500 to-yellow-300",
    badge: 'NEW',
    badgeColor: '#f77c08'
  },
  {
    name: 'Free Instagram Tools',
    description: "Explore Instagram AI tools, templates & more",
    href: '/free-instagram-tools',
    icon: TbTools,
    iconClass: "bg-gradient-to-br from-blue-400 to-blue-600",
  },
  {
    name: 'UpGrow Boost™',
    description: 'Try blazing-fast Instagram AI followers boost ⚡️',
    href: '#',
    icon: TbSparkles,
    iconClass: "bg-gradient-to-br from-amber-700 via-gray-900 to-blue-700",
    // iconClass: "bg-gradient-to-br from-gray-400 via-gray-600 to-blue-800",
    badge: 'BETA',
  },
];

const labsDropdown = [
  {
    name: 'Instagram Marketing Blog',
    description: 'Tips, tricks & best practices for Instagram success',
    href: '/blog',
    icon: MdOutlineTipsAndUpdates,
  },
  {
    name: 'Free Instagram Tools',
    description: 'Explore Instagram AI tools, templates & more',
    href: '/free-instagram-tools',
    icon: TbTools,
  },
  {
    name: 'Free AI Content Tools',
    description: "Create high-engaging content automatically",
    href: '/free-ai-tools',
    icon: RiLightbulbFlashLine,
    badge: 'UPDATED',
  },
  {
    name: 'Earn Money!',
    description: 'Earn 35% recurring commission on your referrals',
    href: '/affiliate-program',
    icon: RiMoneyDollarCircleLine,
    badge: 'NEW'
  },
];

const callsToAction = [
  { name: 'See Live Demo', href: '/demo', icon: PlayCircleIcon },
  { name: 'Live Chat Support', href: "javascript:Intercom('showNewMessage')", icon: RiChatSmile3Line },
];

export default function NavLinks({ isColorBackground = false }) {
  return (
    <div className="flex items-center gap-10">
      <Popover className="relative">
        <Popover.Button className={classNames("inline-flex items-center px-3 py-2 text-sm font-semibold leading-6 transition-colors bg-transparent group gap-x-1 rounded-xl duration-10 whitespace-nowrap",
          isColorBackground ? "text-white text-shadow hover:bg-white/10" : "text-gray-900 hover:bg-gray-100"
        )}>
          <span className={classNames("inline-flex items-center text-base font-medium rounded-md outline-none focus:outline-none",
            isColorBackground ? "text-white focus:ring-0" : "text-gray-600 group-hover:text-gray-900 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          )}>
            Platform
          </span>
          <ChevronDownIcon className={classNames("w-5 h-5", isColorBackground ? "text-white" : "")} aria-hidden="true" />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 flex w-screen px-4 mt-5 -translate-x-1/2 left-1/2 max-w-max">
            <div className="flex-auto w-screen max-w-md overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5">
              <div className="p-4">
                {featuresDropdown.map((item) => (
                  <div key={item.name} className="relative flex p-4 rounded-lg group gap-x-6 hover:bg-gray-50">
                    <div className={clsx("mt-1 flex h-12 w-12 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white transition-transform duration-300 group-hover:scale-90",
                      item.iconClass
                    )}>
                      <item.icon className="w-6 h-6 text-white transition-transform duration-300 group-hover:scale-110" aria-hidden="true" />
                    </div>
                    <div>
                      <a href={item.href} className="text-lg font-semibold text-gray-900">
                        {item.badge ? (
                          <span className="flex items-center gap-x-3">
                            {item.name}
                            <span className={`rounded-full py-1 px-2.5 text-xs font-semibold ${item.badgeColor ? `bg-[${item.badgeColor}] bg-opacity-10 text-[${item.badgeColor}]` : 'bg-fuchsia-600/10 text-fuchsia-600'}`}>
                              {item.badge}
                            </span>
                          </span>
                        ) : (
                          item.name
                        )}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="mt-0 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  >
                    <item.icon
                      className="flex-none w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <Anchor href="/pricing" prefetch className={`${classNames("inline-flex items-center px-3 py-2 text-base font-medium transition-colors duration-100 group hover:no-underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-xl whitespace-nowrap",
        isColorBackground ? "text-white hover:bg-white/10" : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
      )} ${isColorBackground && "text-shadow"}`}>
        Pricing
      </Anchor>

      <Anchor href="/reviews" className={classNames("inline-flex items-center px-3 py-2 text-base font-medium transition-colors duration-100 group hover:no-underline focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-xl whitespace-nowrap",
        isColorBackground ? "text-white hover:bg-white/10" : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
      )}>
        Reviews
      </Anchor>

      <Popover className="relative">
        <Popover.Button className={classNames("inline-flex items-center px-3 py-2 text-sm font-semibold leading-6 transition-colors duration-100 bg-transparent group gap-x-1 rounded-xl whitespace-nowrap",
          isColorBackground ? "text-white hover:bg-white/10" : "text-gray-900 hover:bg-gray-100"
        )}>
          <span className={classNames("inline-flex items-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
            isColorBackground ? "text-shadow" : "text-gray-600 group-hover:text-gray-900"
          )}>
            Instagram Labs
          </span>
          <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 flex w-screen px-4 mt-5 -translate-x-1/2 left-1/2 max-w-max">
            <div className="flex-auto w-screen max-w-md overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5">
              <div className="p-4">
                {labsDropdown.map((item) => (
                  <div key={item.name} className="relative flex p-4 rounded-lg group gap-x-6 hover:bg-gray-50">
                    <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 bg-gray-50 group-hover:bg-white">
                      <item.icon className="w-6 h-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </div>
                    <div>
                      <Anchor href={item.href} className="text-lg font-semibold text-gray-900">
                        {item.badge ? (
                          <span className="flex items-center gap-x-3">
                            {item.name}
                            <span className="rounded-full bg-indigo-600/10 py-1 px-2.5 text-xs font-semibold text-indigo-600">
                              {item.badge}
                            </span>
                          </span>
                        ) : (
                          <>{item.name}</>
                        )}
                        <span className="absolute inset-0" />
                      </Anchor>
                      <p className="mt-0 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                {callsToAction.map((item) => (
                  <Anchor className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                    key={item.name}
                    href={item.href}
                  >
                    <item.icon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                    {item.name}
                  </Anchor>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}