// Hook to get the account platform from the URL
'use client';
import { useState, useEffect } from 'react';
import { useParams } from 'next/navigation';
import { getPlatformForAccount } from '@/lib/utils';

export function useAccountPlatform(): string {
  const params = useParams();
  const accountId = params?.accountId as string;
  const [platform, setPlatform] = useState<string>('instagram');

  useEffect(() => {
    if (accountId) {
      const storedPlatform = getPlatformForAccount(accountId);
      setPlatform(storedPlatform);
    }
  }, [accountId]);

  return platform;
}