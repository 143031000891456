"use client"
import { AnimatePresence, motion } from 'framer-motion';
import { Popover } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { hasCookie } from 'cookies-next';
import Anchor from 'shared-ui/components/Anchor';

function MobileNavLink({ children, ...props }) {
    return (
        <Anchor className="block text-2xl leading-10 tracking-tight text-gray-700"
            href={props.href}
            {...props}
        >
            {children}
        </Anchor>
    );
}

export default function LandingNavbarPopover({ children, isWhiteVersion = false }: { children?: React.ReactNode, isWhiteVersion?: boolean }) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const cookies = Object.fromEntries(document.cookie.split('; ').map(cookie => cookie.split('=')));
        const supabaseAuthCookie = Object.keys(cookies).find(name => /^sb-.+-auth-token$/.test(name));
        setIsLoggedIn(hasCookie('supabase-auth-token') || hasCookie(supabaseAuthCookie));
    }, []);

    return (
        <Popover className="lg:hidden">
            {({ open, close }) => (
                <>
                    <Popover.Button className={`${isWhiteVersion ? 'stroke-white' : 'stroke-gray-900 hover:stroke-gray-600 active:stroke-gray-900'} relative z-10 -m-2 inline-flex items-center rounded-lg p-2 hover:bg-gray-200/50 [&:not(:focus-visible)]:focus:outline-none`} aria-label="Toggle site navigation">
                        {({ open }) =>
                            open ? (
                                // Chevron Up icon
                                <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className="w-6 h-6" stroke="black">
                                    <path d="M17 14l-5-5-5 5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            )
                                : (
                                    // Menu icon
                                    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className="w-6 h-6">
                                        <path d="M5 6h14M5 18h14M5 12h14" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                )
                        }
                    </Popover.Button>
                    <AnimatePresence initial={false}>
                        {open && (
                            <>
                                <Popover.Overlay className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                    static
                                    as={motion.div}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                />
                                <Popover.Panel className="absolute inset-x-0 top-0 z-0 px-6 pt-32 pb-6 origin-top shadow-2xl rounded-b-2xl bg-gray-50 shadow-gray-900/20"
                                    static
                                    as={motion.div}
                                    style={{ willChange: 'opacity, transform' }}
                                    initial={{ opacity: 0, y: -32 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{
                                        opacity: 0,
                                        y: -32,
                                        transition: { duration: 0.2, ease: 'easeOut' },
                                    }}
                                >
                                    {children}
                                    <div className="space-y-4">
                                        <MobileNavLink href="/pricing" onClick={() => { close() }}>
                                            Pricing
                                        </MobileNavLink>
                                        <MobileNavLink href="/free-instagram-tools" onClick={() => { close() }}>
                                            Instagram Tools
                                        </MobileNavLink>
                                        <MobileNavLink href="/reviews" onClick={() => { close() }}>
                                            Reviews
                                        </MobileNavLink>
                                        <MobileNavLink href="/blog" onClick={() => { close() }}>
                                            Blog
                                        </MobileNavLink>
                                    </div>

                                    {!isLoggedIn ? (
                                        <div className="flex flex-col gap-4 mt-8">
                                            <Anchor href="/login" className="px-5 py-4 text-lg font-semibold leading-7 text-gray-800 border-gray-300 shadow-sm rounded-xl border-1 hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white active:bg-gray-100 active:text-gray-700/80"
                                                onClick={() => { close() }}
                                            >
                                                Log in
                                            </Anchor>
                                            <Anchor href="/sign-up" className="px-5 py-4 text-lg font-semibold leading-7 text-white bg-gray-800 shadow-sm rounded-xl hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white active:bg-gray-800 active:text-white/80">
                                                Start for free
                                            </Anchor>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col gap-4 mt-8">
                                            <Anchor href="/app" className="flex justify-between px-5 py-4 text-lg font-semibold leading-7 text-white bg-gray-800 shadow-sm rounded-xl hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white active:bg-gray-800 active:text-white/80">
                                                Go to Dashboard
                                                <svg className="my-auto ml-2 -mr-1 stroke-2 stroke-white" fill="none" width="15" height="15" viewBox="0 0 10 10" aria-hidden="true">
                                                    <path className="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path><path className="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
                                                </svg>
                                            </Anchor>
                                        </div>
                                    )}
                                </Popover.Panel>
                            </>
                        )}
                    </AnimatePresence>
                </>
            )}
        </Popover>
    )
}