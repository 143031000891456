"use client"
import React, { useState, useEffect } from 'react';
import { setCookie, getCookie } from 'cookies-next';
import { LoadingSpinner } from '@/components/presentational/tailwind/LoadingSpinner';
import { BiSolidTimer } from 'react-icons/bi';

export default function BannerSpotsLeft() {
    const [isLoading, setIsLoading] = useState(true);
    const [spotsLeft, setSpotsLeft] = useState(null);

    useEffect(() => {
        const visitTime = getCookie('initialVisitTime');
        if (!visitTime) {
            setCookie('initialVisitTime', Date.now().toString());
        }
        const minutesElapsed = Math.floor((Date.now() - Number(visitTime)) / 60000);
        let initialSpots = 5;
        if (minutesElapsed >= 1) initialSpots--; // 4 spots left
        if (minutesElapsed >= 2) initialSpots--; // 3 spots left
        if (minutesElapsed >= 5) initialSpots--; // 2 spots left
        if (minutesElapsed >= 10) initialSpots--; // 1 spot left

        setSpotsLeft(initialSpots);
        setIsLoading(false);

        const interval = setInterval(() => {
            const visitTime = getCookie('initialVisitTime');
            const minutesElapsed = Math.floor((Date.now() - Number(visitTime)) / 60000);
            let spots = 5;
            if (minutesElapsed >= 1) spots--; // 4 spots left
            if (minutesElapsed >= 2) spots--; // 3 spots left
            if (minutesElapsed >= 5) spots--; // 2 spots left
            if (minutesElapsed >= 10) spots--; // 1 spot left
            setSpotsLeft(spots);
        }, 60000); // Check every minute
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {isLoading && <LoadingSpinner className='absolute w-4 h-4 left-1/2' />}

            <span className={`inline-flex ${isLoading ? 'opacity-0' : 'animate-fade-in'}`}>
                <BiSolidTimer className='mr-1.5 text-yellow-300 font-bold w-4 h-4 ' />
                {spotsLeft} SPOT{spotsLeft === 1 ? '' : 'S'} LEFT
            </span>
        </>
    );
}